import * as Icons from "./icons";
import { useEffect, useState } from "react";
import '../css/footer.css';
import MangeBy from '../assets/mangedBy.png'
import Insta from '../assets/svgs/instagram.svg';
import youtube from '../assets/svgs/youtube.svg';
import spotify from '../assets/svgs/spotify.svg';
import tiktok from '../assets/svgs/tiktok.svg';
import map from '../assets/icons/map_icon 1.png'
import phone from '../assets/svgs/phone 2.svg'
import email from '../assets/svgs/email icon 1.svg'
import linktree from '../assets/svgs/lintree.svg'
import emailjs from 'emailjs-com';
import axios from "axios";





const EmailIcon = () => {
    return (
        <svg style={{ alignSelf: 'flex-end' }} xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
            <path d="M18.6327 0.0165269C13.5446 -0.00597527 8.45644 -0.00503768 3.36831 0.0165269C1.38665 0.0249652 0.00305655 1.36291 0.00305655 3.17433C0.0020377 5.82865 0.00509424 8.48203 0 11.1354C0 11.5639 0.0855833 11.9727 0.279165 12.358C0.916964 13.6285 2.03566 14.2004 3.52216 14.2051C4.76821 14.2088 6.01528 14.2088 7.26235 14.2088C8.50841 14.2088 9.75548 14.207 11.0025 14.207C11.6302 14.207 12.2578 14.207 12.8854 14.2079C14.7682 14.2098 16.651 14.2117 18.5329 14.2042C20.6001 14.1957 22 12.8887 22 10.9938C22 8.40234 22 5.81178 22 3.22027C22 1.36291 20.6358 0.0249652 18.6337 0.0155893L18.6327 0.0165269ZM18.0143 2.06235C16.918 2.66428 15.9307 3.20621 14.9455 3.74908C13.7433 4.41102 12.539 5.07014 11.3428 5.74239C11.1197 5.86803 10.9526 5.9121 10.704 5.77427C8.51146 4.55072 6.30973 3.34122 3.99287 2.06329H18.0143V2.06235ZM19.7769 11.1654C19.7738 11.788 19.3235 12.1668 18.6419 12.1668C13.5527 12.1696 8.4646 12.1696 3.37545 12.1668C2.64799 12.1668 2.22313 11.7795 2.22109 11.0857C2.21192 8.55704 2.21701 6.0293 2.21701 3.44436C4.92613 4.93888 7.57922 6.39308 10.218 7.86791C10.758 8.16982 11.2247 8.17919 11.7687 7.87541C14.3668 6.42309 16.9791 4.99232 19.5874 3.55406C19.6403 3.52499 19.6964 3.49968 19.782 3.45749C19.782 6.0518 19.7891 8.60861 19.7769 11.1654Z" fill="white" />
        </svg>
    )
}


const SendIcon = ({ onClick }) => {
    return (
        <svg onClick={onClick} transform="scale(1.5)" width="22px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </g>
        </svg>
    );
}


function Footer({ elements, edit, select, selected }) {
    const [SectionData, setSectionData] = useState(null);
    const [emailText, setEmail] = useState(null);
    const [styles, setStyles] = useState(null);
    const [target, setTarget] = useState(null);
    const [disabled, setdisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const FooterImage = 'https://imagedelivery.net/pPmcWI57Uxy-_rb5sInlGg/c1f57453-7a9a-45b6-baf1-24749c248900/public'
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);

    }, []);
    const handleTarget = (tar) => {
        setTarget(tar);
    }
    const handleRemoveTarget = (tar) => {
        setTarget(null);
    }
    const handleElementEdit = (element) => {
        select({ select_from: 'footer', element: element });
        edit(true);
    }
    useEffect(() => {
        emailjs.init("GEbmHxt_KFG6CW3Lr");

    }, []);
    const handleSendEmail = (e) => {
        e.preventDefault();
        if (emailText) {
            setLoading(true);
            setdisabled(true)
            /* var params = {
                
                from_email: emailText,
                to_name: 'mokhtar farza',
    
                reply_to: emailText,
                sauce: 'footer'
                
            };
            emailjs.send('service_33h3afj', 'template_n0qferr', params)
                .then(function () {
                    setLoading(false);
                    setDone(true);
                }, function (error) {
                    
                    
                    
                    setLoading(false);
                    console.error('Error sending email:', error);
                }); */
            const data = {
                subject: "Welcome to the best Fitness newsletter!",
                body: "undefiend",
                sender: emailText
            };
            axios.post('https://trainwithmo.com/mail.php', data)
                .then(function (response) {
                    // Handle success
                    console.log('Response from server:', response.data);
                    setDone(true);
                    setLoading(false);
                })
                .catch(function (error) {
                    // Handle error
                    console.error('Error:', error);
                    setLoading(false);
                });
            axios.post('https://trainwithmo.com/mail-to-the-coach.php', data)
                .then(function (response) {
                    // Handle success
                    console.log('Response from server:', response.data);
                    setDone(true);
                    setLoading(false);
                })
                .catch(function (error) {
                    // Handle error
                    console.error('Error:', error);
                    setLoading(false);
                });

        }
    }
    return (
        <div className="footer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
            <div className="footer-contact" style={{
                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 90, background: `url(${FooterImage})`, position: 'relative'}}>

                {
                    SectionData ?
                        <>
                            <div style={{ maxWidth: `${70}%`, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <p style={{ fontSize: 42, color: 'white', marginBlock: 10 }} dangerouslySetInnerHTML={{ __html: SectionData.f_title }}
                                    onClick={() => handleElementEdit('f_title')}
                                    onMouseLeave={handleRemoveTarget}
                                    onMouseEnter={() => handleTarget('f_title')}
                                    className={`robot-condesed-title ${target == "f_title" ? 'active-target' : ''}  ${selected.element == "f_title" ? 'active-selection' : ''}`}
                                />

                                <p style={{ fontSize: 16, color: 'white', marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: SectionData.f_subtitle }}
                                    onMouseLeave={handleRemoveTarget}
                                    onClick={() => handleElementEdit('f_subtitle')}
                                    onMouseEnter={() => handleTarget('f_subtitle')}
                                    className={`${target == "f_subtitle" ? 'active-target' : ''}  ${selected.element == "f_subtitle" ? 'active-selection' : ''}`}
                                />

                                {!done && <form onSubmit={(e) => handleSendEmail(e)} style={{ width: "80%", borderBottom: '2px solid white', padding: 0, display: 'flex', flexDirection: 'column', position: 'relative' }}
                                    onMouseLeave={handleRemoveTarget}

                                    onMouseEnter={() => handleTarget('email_input')}
                                    className={`${target == "email_input" ? 'active-target' : ''}  ${selected.element == "f_place_holder" ? 'active-selection' : ''}`}>
                                    <input onClick={() => handleElementEdit('f_place_holder')} required placeholder=" " type="email" style={{ width: '100%', padding: '1rem', backgroundColor: 'transparent', border: 'none', outline: 'none', color: 'white', transform: 'translateY(30px)' }} onChange={(e) => setEmail(e.target.value)} />
                                    <div className="label" style={{ width: '100%', display: 'flex', marginBottom: 3 }}>
                                        <label style={{ color: 'white', alignSelf: 'flex-start', pointerEvents: 'none', width: '100%', textAlign: 'left' }}>{SectionData.f_place_holder} </label>  {!emailText ? <EmailIcon /> : <SendIcon onClick={disabled ? () => { } : (e) => handleSendEmail(e)} />}
                                    </div>

                                </form>}
                                {
                                    done &&
                                    <div>
                                        <p style={{ color: 'white', fontFamily: '600', fontSize: 21 }}>Thanks for subscribing </p>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <>
                        </>
                }

                {loading &&
                    <>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', bottom: 0, zIndex: 1, background: 'rgba(0,0,0,.3)', backdropFilter: 'blur(10px)' }}></div>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 10 }}> <h2 style={{color: 'white', fontSize: 25}}>Sending ....</h2></div>
                    </>
                }

            </div>
            <div style={{ width: '100%', background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: 'white', position: 'relative' }}>
                <div className="links-footer" style={{ width: '80%', background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '3rem', flexDirection: 'column' }}>
                    {/* <ul>
                        <a href="https://linktr.ee/coach_mo_"  target="_blank"><li><Icons.Linktree size={12} /> <p>Linktree</p></li></a>
                        <a href="https://www.youtube.com/channel/UC0wKFL3CsqHQQzBxKi_2G5Q"  target="_blank"><li><Icons.Youtube size={12} /> <p>Youtube</p></li></a>
                        <a href="https://instagram.com/coach_mokhtar_?igshid=MzRlODBiNWFlZA=="  target="_blank"><li><img src={Insta} /> <p>Instagram</p></li></a>
                        <a href="https://www.tiktok.com/@coach_mo_?_t=8gpbZg4YAqH&_r=1"  target="_blank"><li> <img src={tiktok} /> <p>Tiktok</p></li></a>
                        <a href="https://open.spotify.com/user/3156estmup5se3vj4g2wvtyg3wlq"  target="_blank"><li> <Icons.Spotify size={12} /> <p>Spotify </p></li></a>
                    </ul> */}
                    <ul style={{ flexDirection: 'row', minWidth: 350 }}>
                        <a href="https://www.instagram.com/trainwithmodxb/" target="_blank"> <img src={Insta} /> </a>
                        <a href="https://www.tiktok.com/@trainwithmodxb" target="_blank"> <img src={tiktok} /> </a>
                        <a href="https://open.spotify.com/user/3156estmup5se3vj4g2wvtyg3wlq" target="_blank">  <img style={{ marginTop: -6 }} src={spotify} /></a>
                        <a href="https://www.youtube.com/channel/UC0wKFL3CsqHQQzBxKi_2G5Q" target="_blank">  <img style={{ marginTop: -6 }} src={youtube} /></a>
                    </ul>
                    {/* <div className="divider"></div> */}
                    <ul style={{ width: 'max-content' }}>
                        <a href="https://linktr.ee/trainwith_MO" target="_blank"> <li> <img src={linktree} />Linktree</li></a>
                        <a href="tel:+971 50 210 8327"> <li> <img src={phone} />+971 50 210 8327</li></a>
                        <a href="mailto:info@trainwithmo.com"><li><img src={email} />  info@trainwithmo.com</li></a>

                    </ul>
                </div>
                <div className="terms">
                    <a href="https://trainwithmo.com/Fitness-Training-Agreement.html" ><h2>Terms & Conditions</h2></a>
                    <h2>© trainwithmo.com</h2>

                </div>
                <a href="https://huppeandco.com" style={{ width: 'unset' }} className="branding-log"> <img style={{ width: 100 }} src={MangeBy} /> </a>
            </div>
        </div>
    )
}

export default Footer;