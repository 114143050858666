import { PlayIcon } from "./icons";


function Banner ({bannerTxt, bg, handlePlay}) {
    return (
        <div  style={{height: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'yellow', position: 'relative', zIndex: 300, padding: 3, background: bg}}>
            <div className="flash">
            <p className="banner-txt robot-condesed" dangerouslySetInnerHTML={{__html: bannerTxt == "Make sure the sound is on and hit play!" ? `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="27" viewBox="0 0 36 27" fill="none">
            <g clip-path="url(#clip0_170_20)">
                <path d="M18.002 1.49229C18.002 0.860074 17.7275 0.377485 17.124 0.121363C16.5246 -0.133411 16 0.0296984 15.5326 0.457692C12.9508 2.82278 10.3626 5.1818 7.78149 7.54756C7.63479 7.68169 7.49089 7.73561 7.29041 7.73493C5.39313 7.72819 3.49586 7.73022 1.59858 7.73224C1.42534 7.73224 1.24651 7.73291 1.07956 7.77066C0.369129 7.9304 0.00518214 8.44736 0.00378503 9.2966C0.000292258 10.9809 0.00308648 12.6653 0.00308648 14.3503C0.00308648 15.4954 -0.00320051 16.6412 0.00378503 17.7864C0.010072 18.8115 0.545863 19.3258 1.58811 19.3265C3.45045 19.3265 5.3128 19.3339 7.17514 19.3191C7.46924 19.317 7.66972 19.4067 7.87579 19.5954C10.4213 21.9315 12.9745 24.2602 15.5243 26.5923C15.6346 26.6927 15.7436 26.7891 15.8847 26.8518C16.9298 27.3108 18.0006 26.6543 18.0013 25.5469C18.0034 21.5494 18.002 17.5512 18.002 13.5536C18.002 9.5561 18.002 5.51274 18.002 1.49296V1.49229Z" fill="#010101" />
                <path d="M31.2283 2.54027C30.4529 1.78875 29.3786 1.7254 28.5487 2.37109C27.5574 3.14216 27.5484 4.36009 28.4816 5.31583C31.8396 8.7546 32.7784 12.7508 31.3387 17.2518C30.7806 18.9975 29.7271 20.4708 28.402 21.7697C27.5812 22.5737 27.5812 23.7148 28.3768 24.4778C29.1795 25.2475 30.3971 25.2664 31.1871 24.4933C34.3006 21.4495 35.9359 17.7991 35.9995 13.689C36.003 10.8777 35.2793 8.2464 33.9087 5.92715C33.1731 4.68226 32.2748 3.55465 31.2276 2.5396L31.2283 2.54027Z" fill="#020202" />
                <path d="M28.0953 11.2654C27.7299 9.23053 26.5815 7.62707 25.0796 6.22851C24.6737 5.85039 24.154 5.71155 23.5945 5.80861C22.8442 5.93869 22.3308 6.36668 22.0891 7.05956C21.8474 7.75176 22.029 8.37185 22.5634 8.87803C23.8159 10.0643 24.511 11.4878 24.5417 13.189C24.5773 15.1341 23.9451 16.8461 22.5592 18.277C21.964 18.8917 21.8586 19.6743 22.1946 20.386C22.6745 21.4011 24.6605 21.4038 25.2668 20.7803C25.6811 20.3543 26.1016 19.9364 26.4439 19.4525C27.7411 17.6199 28.2392 15.5642 28.2664 13.377C28.2615 12.668 28.2196 11.963 28.0939 11.2647L28.0953 11.2654Z" fill="#020202" />
            </g>
            <defs>
                <clipPath id="clip0_170_20">
                    <rect width="36" height="27" fill="white" />
                </clipPath>
            </defs>
        </svg> ${bannerTxt}` : bannerTxt }} style={{ fontSize: 34, textTransform: 'uppercase', fontWeight:  '600' }}/>
          

            </div>
           
        </div>
    )
}

export default Banner;