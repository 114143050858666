import SectionOne from './section1';
import '../../css/home.css';
import SectionTwo from './section2';
import SectionThree from './section3';
import { useState, useEffect } from 'react';
import SectionFour from './sectionFour';



function isNumberInput(str) {
  // Check if the string is not empty
  if (str.length > 0) {
    // Convert the first character to lowercase and compare it to "n"
    return str[0].toLowerCase() === 'n';
  }
  // If the string is empty, return false
  return false;
}
function Home({selected, setSelected, data, setEditorVisible, handleAddMoreToList, handleTypeFormClick, setBanner}) {
  const [elements, setElements] = useState(null);
  

  useEffect(() => {
    setElements(data);
    
  }, [])

  
  return (
    <div className='home'>
      {
        elements ?
          <>
            <SectionOne elements={elements.one} edit={setEditorVisible} select={setSelected} selected={selected}handleTypeFormClick={handleTypeFormClick} setBanner={setBanner}/>
            <SectionTwo elements={elements.two} edit={setEditorVisible} select={setSelected} selected={selected} handleAddMoreToList={handleAddMoreToList} handleTypeFormClick={handleTypeFormClick}/>
            <SectionThree elements={elements.three} edit={setEditorVisible} select={setSelected} selected={selected} handleTypeFormClick={handleTypeFormClick}/>
            <SectionFour elements={elements.four} edit={setEditorVisible} select={setSelected} selected={selected} handleTypeFormClick={handleTypeFormClick} />
          </>
          :
          <></>
      }

     
    </div>
  )
}

export default Home;