import { useEffect, useState, useRef } from "react";
import { SoundOn, MutedSound, PlayButton, PauseButton, Spinner, Restart } from "../../components/icons";
import { Stream } from "@cloudflare/stream-react";
import WebVideo from '../../assets/videos/movideo_web.mp4';
import MobileVideo from '../../assets/videos/movideo_mbl.mp4';



function Section({ elements, edit, select, selected, handleTypeFormClick, setBanner }) {
    const [data, setData] = useState(null);
    const [styles, setStyles] = useState(null);
    const [target, setTarget] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [displayHeroContent, setDisplayHeroContent] = useState(true);
    const [barVISIBLE, setBarVisIBLE] = useState(false);
    const [fabVisible, setFabVisible] = useState(true);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [iframeVideo, setiframeVideo] = useState("https://customer-m9ag1mw3bi77o9lq.cloudflarestream.com/705c9784bbe50270ca4ade7d8feca23b/iframe?muted=true&preload=true&loop=true&autoplay=false&poster=https%3A%2F%2Fcustomer-m9ag1mw3bi77o9lq.cloudflarestream.com%2F705c9784bbe50270ca4ade7d8feca23b%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false")
    const [VideoSource, setVideoSource] = useState(WebVideo);
    const iframeRef = useRef(null);
    const videoRef = useRef(null);
    let [VideoSound, setVideoSound] = useState(true);
    let [videoPaying, setVidePlaying] = useState(false);
    let sound = document.getElementById('page-audio');
    let [play, setPlay] = useState(false);
    /*  const sound = new Audio("https://trainwithmo.com/movideo_audio.mp3"); */

    useEffect(() => {
        setData(elements.data);
        setStyles(elements.styles);
        setTimeout(() => {
            setIsLoaded(true);



        }, 3000);



        const windowWidth = window.innerWidth;
        if (windowWidth < 800) {
            setiframeVideo("https://customer-m9ag1mw3bi77o9lq.cloudflarestream.com/107e6a27a415a2aa6fa3874c20cc4d16/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-m9ag1mw3bi77o9lq.cloudflarestream.com%2F107e6a27a415a2aa6fa3874c20cc4d16%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false");
            setVideoSource(MobileVideo);
        }
        const eventListener = window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                setBarVisIBLE(false);
                setDisplayHeroContent(true);
                setFabVisible(false);

            } else {
                setFabVisible(true);
            }


        });
        return () => {
            window.removeEventListener('scroll', eventListener)
        }
    }, [])

    const handleElementEdit = (e, element) => {
        e.stopPropagation();
        select({ select_from: 'one', element: element });
        edit(true);
        console.log(element);
    }
    useEffect(() => {
        setData(elements.data);
        setStyles(elements.styles);

    }, [elements])
    const handleTarget = (e, tar) => {
        e.stopPropagation();

        setTarget(tar);
    }
    const handleRemoveTarget = (tar) => {
        setTarget(null);

    }
    //let video = window.Stream(document.getElementById('stream-player'));

    const handlePlay = () => {

        // sound.muted = false;
        //console.log(videoControls.autoplay);
        console.log("entered handlePlay");

        //reloadIframe();
        if (displayHeroContent || sound.paused) {
            window.scrollTo(0, 0);
            setBanner({ bg: '#fcf300', text: "⚡<span style='font-weight: 600 !important'>LIMITED OFFER!</span> Get 20% Off when you book a consultation today. Valid until Dec 31<sup>ST</sup>.⚡" });
            setDisplayHeroContent(false);
            setBarVisIBLE(true);
            setTimeout(() => {
                sound.play();
                setPlay(true);
                setVidePlaying(true);
                videoRef.current.play();
                sound.addEventListener('ended', function () {
                    // This code will run when the audio has finished playing
                    setDisplayHeroContent(true);
                });

            }, 100);

        } else {
            sound.pause();
            setDisplayHeroContent(false);
            /* const iframe = iframeRef.current;
            iframe.src = iframeVideo */
            setPlay(true);
            setVidePlaying(true);

        }
    }
    const handleStop = () => {
        /* sound.play();
        if (iframeRef.current) {
            const iframe = iframeRef.current;
        if(window.innerWidth < 800) {
            iframe.src = 'https://customer-m9ag1mw3bi77o9lq.cloudflarestream.com/107e6a27a415a2aa6fa3874c20cc4d16/iframe?muted=true&preload=true&autoplay=true&poster=https%3A%2F%2Fcustomer-m9ag1mw3bi77o9lq.cloudflarestream.com%2F107e6a27a415a2aa6fa3874c20cc4d16%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false';
            videoControls.play();
            
         } else {
             
             iframe.src = 'https://customer-m9ag1mw3bi77o9lq.cloudflarestream.com/705c9784bbe50270ca4ade7d8feca23b/iframe?muted=true&preload=true&autoplay=true&poster=https%3A%2F%2Fcustomer-m9ag1mw3bi77o9lq.cloudflarestream.com%2F705c9784bbe50270ca4ade7d8feca23b%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false';
             videoControls.play();
         }
        } */
        setDisplayHeroContent(true);
        videoRef.current.pause();
        sound.pause();
        setVidePlaying(false);

    }
    const reloadIframe = () => {
        setIsLoaded(false);
        setBtnDisabled(true);
        setTimeout(() => {
            setIsLoaded(true);
            setBtnDisabled(false);
        }, 3000)
        if (iframeRef.current) {
            const iframe = iframeRef.current;

            // Get the current iframe source
            const currentSrc = iframe.src;
            // iframe.src = currentSrc + `?timestamp=${Date.now()}`;

            if (window.innerWidth < 800) {
                iframe.src = 'https://customer-m9ag1mw3bi77o9lq.cloudflarestream.com/107e6a27a415a2aa6fa3874c20cc4d16/iframe?preload=true&autoplay=true&poster=https%3A%2F%2Fcustomer-m9ag1mw3bi77o9lq.cloudflarestream.com%2F107e6a27a415a2aa6fa3874c20cc4d16%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false';


            } else {

                iframe.src = 'https://customer-m9ag1mw3bi77o9lq.cloudflarestream.com/705c9784bbe50270ca4ade7d8feca23b/iframe?preload=true&autoplay=true&poster=https%3A%2F%2Fcustomer-m9ag1mw3bi77o9lq.cloudflarestream.com%2F705c9784bbe50270ca4ade7d8feca23b%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false';

            }


            setTimeout(() => {
                iframe.src = iframeVideo

            }, 53000);
        }


    };
    const handleMuteSound = () => {
        videoRef.current.muted = true;
        setVideoSound(false)
    }
    const handleSoundOn = () => {
        videoRef.current.muted = false;
        setVideoSound(true)
    }
    const handleRestart = () => {
        let video = videoRef.current;
        video.currentTime = 0;
        video.play();
    }

    return (
        <section className="section-one">
            {
                elements ?
                    <>
                        {displayHeroContent  &&
                            <div onMouseLeave={handleRemoveTarget}
                                onMouseEnter={(e) => handleTarget(e, 'hero_content')}
                                onClick={(e) => handleElementEdit(e, 'hero_content')}
                                className={`hero_content ${target == "hero_content" ? 'active-target' : ''} ${selected.element == "hero_content" ? 'active-selection' : ''}`}
                                style={{ marginBlock: parseInt(styles?.hero_content?.n_marginBlock), marginInline: parseInt(styles?.hero_content?.n_marginInline) }}>



                                <p onMouseLeave={handleRemoveTarget}
                                    onMouseEnter={(e) => handleTarget(e, 'hero-title')}
                                    className={`hero-title ${target == "hero-title" ? 'active-target' : ''} ${selected.element == "hero_title" ? 'active-selection' : ''}`}
                                    onClick={(e) => handleElementEdit(e, 'hero_title')}
                                    style={{
                                        fontSize: `${parseInt(styles?.hero_title?.n_fontSize)}px`,
                                        color: styles?.hero_title?.color,
                                        fontWeight: styles?.hero_title?.fontWeight,
                                        textAlign: styles?.hero_title?.textAlign
                                    }}>
                                    {data?.hero_title}
                                </p>

                                <p onMouseEnter={(e) => handleTarget(e, 'hero-subtitle')}
                                    className={`hero-subtitle ${target == "hero-subtitle" ? 'active-target' : ''}  ${selected.element == "hero_subtitle" ? 'active-selection' : ''}`}
                                    onClick={(e) => handleElementEdit(e, 'hero_subtitle')}
                                    style={{
                                        fontSize: `${parseInt(styles?.hero_subtitle?.n_fontSize)}px`,
                                        color: styles?.hero_subtitle?.color,
                                        fontWeight: styles?.hero_subtitle?.fontWeight,
                                        textAlign: styles?.hero_subtitle?.textAlign,
                                        marginTop: -2
                                    }}>
                                    {data?.hero_subtitle}
                                </p>

                                <a onMouseEnter={(e) => handleTarget(e, 'play-btn')}
                                    className={`play-btn ${target == "play-btn" ? 'active-target' : ''} ${selected.element == "hero_button" ? 'active-selection' : ''}`}
                                    onClick={(e) => { handleElementEdit(e, 'hero_button'); handleStop(); handleTypeFormClick() }}>
                                    {/*  <PlayIcon size={parseInt(styles?.hero_button?.n_fontSize)} /> */}

                                    <p style={{
                                        fontSize: `${parseInt(styles?.hero_button?.n_fontSize)}px`,
                                        color: styles?.hero_button?.color, fontWeight: styles?.hero_button?.fontWeight,
                                        textAlign: styles?.hero_button?.textAlign,
                                        paddingInline: '1rem',
                                        margin: 8
                                    }}
                                        dangerouslySetInnerHTML={{ __html: data?.hero_button }} onClick={handleStop} />
                                </a>

                            </div>
                        }
                    </>
                    :
                    <>
                    </>
            }


            <div className="hero_video">
                {!isLoaded ? ( // Render the skeleton loader if the video is not loaded
                    <div className="skeleton-loader" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/*   {!displayHeroContent && <h1 className="animated-text" style={{fontFamily: 'Dhurjati', fontSize: '5vw', lineHeight: '100%'}}>Nothing Will Work Unless You Do <br /> <sub>—angela hares</sub> </h1>} */}
                    </div>
                ) :
                    (<></>)
                }
                <>
                    <iframe
                        src={iframeVideo}
                        id="stream-player"
                        ref={iframeRef}
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowFullScreen={false}
                        frameborder="0"
                        style={{ display: isLoaded && !play ? 'block' : 'none' }}
                    // Set the isLoaded state to true when the video is loaded
                    ></iframe>
                    <video onEnded={() => setPlay(false)} ref={videoRef} style={{ display: play ? 'block' : 'none' }} autoPlay playsInline >
                        <source src={VideoSource} type="video/mp4" />

                        Your browser does not support the video tag.
                    </video>
                </>


            </div>

            {
                !play ?
                    <button onClick={handlePlay} className='fab' style={{ opacity: fabVisible ? '' : 0, position: 'fixed', background: 'transparent', boxShadow: 'none', display: 'flex' }} disabled={btnDisabled}>
                        {!isLoaded ? <Spinner /> : displayHeroContent && isLoaded && sound.paused ? <PlayButton /> : <PauseButton />}
                    </button>
                    :
                    <div className="video-controls"  style={{ opacity: fabVisible ? '' : 0}}>
                        {!videoPaying ? <PlayButton onPress={handlePlay} /> : <PauseButton onPress={handleStop} />}
                        {!VideoSound ? <MutedSound onPress={handleSoundOn} /> : <SoundOn onPress={handleMuteSound} />}
                        <Restart onPress={handleRestart}  />
                    </div>
            }



        </section>
    )
}
/* style={{
                    border: 'none',
                    position: 'absolute',
                    top: "0%",
                    left: 0,
                    height: '100%',
                    objectFit: 'cover',
                    width: '100%',
                    backgroundColor: 'red',

                }} */

export default Section;