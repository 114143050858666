import FleceeOne from '../../assets/8sl3kgwruvqb1.jpg';
import FleceTwo from '../../assets/fleee johnson.jpg';
import { useState, useEffect } from 'react';


function SectionThree({ elements, edit, select, selected, handleTypeFormClick }) {
    const [SectionData, setSectionData] = useState(null);
    const [styles, setStyles] = useState(null);
    const [target, setTarget] = useState(null);
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);


    }, []);
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);

    }, [elements]);
    const handleTarget = (e, tar) => {
        e.stopPropagation();
        setTarget(tar);
    }
    const handleRemoveTarget = (tar) => {
        setTarget(null);
    }
    const handleElementEdit = (e, element) => {
        select({ select_from: 'three', element: element });
        e.stopPropagation();
        edit(true);
    }
    return (
        <div style={{ paddingTop: 130, background: styles?.container?.bgColor }}
            onMouseLeave={handleRemoveTarget}
            onMouseEnter={(e) => handleTarget(e, 'sec3-container')}
            onClick={(e) => handleElementEdit(e, 'container')}
            className={`section-three ${target == "sec3-container" ? 'active-target' : ''} ${selected.element == "sec3-container" ? 'active-selection' : ''}`}
        >
            {SectionData ?
                <>
                    <div className="section-three-container">
                        <div className="s_th_txt" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginInline: 20 }} >
                            <p dangerouslySetInnerHTML={{ __html: SectionData?.title }}
                                onMouseLeave={handleRemoveTarget}
                                onMouseEnter={(e) => handleTarget(e, 'sec3-title')}
                                className={`${target == "sec3-title" ? 'active-target' : ''} ${selected.element == "title" ? 'active-selection' : ''}`}
                                onClick={(e) => handleElementEdit(e, 'title')}
                                style={{
                                    backgroundColor: styles?.title?.bgColor, color: styles?.title?.color, fontSize: parseInt(styles?.title?.n_fontSize),
                                    marginTop: parseInt(styles?.title?.n_marginTop),
                                    marginBottom: parseInt(styles?.title?.n_marginBottom),
                                    lineHeight: `${styles?.title?.n_lineHeight}px`,
                                    fontWeight: styles?.title?.fontWeight, padding: styles?.title?.n_padding
                                }}
                            />

                            <p dangerouslySetInnerHTML={{ __html: SectionData?.subtitle }}
                                onMouseLeave={handleRemoveTarget}
                                onMouseEnter={(e) => handleTarget(e, 'sec3-subtitle')}
                                className={`robot-condesed ${target == "sec3-subtitle" ? 'active-target' : ''} ${selected.element == "subtitle" ? 'active-selection' : ''}`}
                                onClick={(e) => handleElementEdit(e, 'subtitle')}
                                style={{
                                    backgroundColor: styles?.subtitle?.bgColor, color: styles?.subtitle?.color, fontSize: parseInt(styles?.subtitle?.n_fontSize),
                                    marginTop: parseInt(styles?.subtitle?.n_marginTop),
                                    marginBottom: parseInt(styles?.subtitle?.n_marginBottom),
                                    lineHeight: `${styles?.subtitle?.n_lineHeight}px`,
                                    fontWeight: styles?.subtitle?.fontWeight, padding: styles?.subtitle?.n_padding
                                }}
                            />

                            <p dangerouslySetInnerHTML={{ __html: SectionData?.text }}
                                onMouseLeave={handleRemoveTarget}
                                onMouseEnter={(e) => handleTarget(e, 'sec3-text')}
                                className={`robot-condesed ${target == "sec3-text" ? 'active-target' : ''} ${selected.element == "text" ? 'active-selection' : ''}`}
                                onClick={(e) => handleElementEdit(e, 'text')}
                                style={{
                                    maxWidth: parseInt(styles?.text?.n_maxWidth), textAlign: styles?.text?.textAlign,
                                    backgroundColor: styles?.text?.bgColor, color: styles?.text?.color, fontSize: parseInt(styles?.text?.n_fontSize),
                                    marginTop: parseInt(styles?.text?.n_marginTop),
                                    marginBottom: parseInt(styles?.text?.n_marginBottom),
                                    lineHeight: `${parseInt(styles?.text?.n_lineHeight)}px`,
                                    fontWeight: styles?.text?.fontWeight, padding: parseInt(styles?.text?.n_padding),
                                    

                                }}

                            />
                        </div>
                        <div style={{
                            width: 250, height: 500,
                            marginTop: parseInt(styles?.images_container?.n_marginTop),
                            marginBottom: parseInt(styles?.images_container?.n_marginBottom),
                            marginLeft: parseInt(styles?.images_container?.n_marginLeft),
                            marginRight: parseInt(styles?.images_container?.n_marginRight),
                        }}
                            onMouseLeave={handleRemoveTarget}
                            onMouseEnter={(e) => handleTarget(e, 'images')}
                            className={`s_th_imges  ${target == "images" ? 'active-target' : ''}  ${selected.element == "images" ? 'active-selection' : ''}`}
                            onClick={(e) => handleElementEdit(e, 'images')
                            }
                        >
                            <div className='img-fix-sm-sc'>
                                {SectionData?.images.map((image, index) => {
                                    return <img src={image}
                                   
                                        style={{
                                            width: parseInt(styles?.images[index]?.n_width),
                                            height: parseInt(styles?.images[index]?.n_height),
                                            transform: `translate(${styles?.images[index]?.n_displacementX}px, ${styles?.images[index]?.n_displacementY}px)`
                                        }} key={index} />
                                })}


                            </div>
                        </div>

                    </div>
                    <p dangerouslySetInnerHTML={{ __html: SectionData?.qoute }}
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={(e) => handleTarget(e, 'qoute')}
                        className={`qoute ${target == "qoute" ? 'active-target' : ''} ${selected.element == "qoute" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'qoute')}
                        style={{
                            backgroundColor: styles?.qoute?.bgColor, color: styles?.qoute?.color, fontSize: parseInt(styles?.qoute?.n_fontSize),
                            marginTop: parseInt(styles?.qoute?.n_marginTop),
                            marginBottom: parseInt(styles?.qoute?.n_marginBottom),
                            lineHeight: `100%`,
                            fontWeight: styles?.qoute?.fontWeight, padding: parseInt(styles?.qoute?.n_padding),
                            fontFamily: 'Dhurjati'
                        }}
                    />
                    <button

                        style={{
                            background: styles?.button?.bgColor, color: styles?.button?.color,
                            fontSize: parseInt(styles?.button?.n_fontSize),
                            marginTop: parseInt(styles?.button?.n_marginTop),
                            marginBottom: parseInt(styles?.button?.n_marginBottom),
                            lineHeight: `${styles?.button?.n_lineHeight}px`,
                            fontWeight: styles?.button?.fontWeight,
                            padding: parseInt(styles?.button?.n_padding),
                            borderRadius: parseInt(styles?.button?.n_borderRadius)
                        }}
                        nMouseLeave={handleRemoveTarget}
                        onMouseEnter={(e) => handleTarget(e, 'button')}
                        className={`sm-sc-fix ${target == "button" ? 'active-target' : ''} ${selected.element == "button" ? 'active-selection' : ''}`}
                        onClick={(e) => { handleElementEdit(e, 'button'); handleTypeFormClick() }}>
                        {SectionData?.button}
                    </button>


                </>
                :
                <></>
            }
        </div>
    )
}

export default SectionThree;