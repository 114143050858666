import { useEffect, useState } from "react";

function SectionFour ({elements, edit, select, selected, handleTypeFormClick}) {
    const [SectionData, setSectionData] = useState(null);
    const [styles, setStyles] = useState(null);
    const [target, setTarget] = useState(null);
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);


    }, []);
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);

    }, [elements]);
    const handleTarget = (e, tar) => {
        e.stopPropagation();
        setTarget(tar);
    }
    const handleRemoveTarget = (tar) => {
        setTarget(null);
    }
    const handleElementEdit = (e, element) => {
        select({ select_from: 'four', element: element });
        e.stopPropagation();
        edit(true);
    }
        return (
            <div className="section-four">
                <div className="container">
                    <div 
                     onMouseLeave={handleRemoveTarget}
                     onMouseEnter={(e) => handleTarget(e, 'sec4_images')}
                     className={`image-container ${target == "sec4_images" ? 'active-target' : ''} ${selected.element == "images" ? 'active-selection' : ''}`}
                     onClick={(e) => handleElementEdit(e, 'images')}>
                       {Array.isArray(SectionData?.images) &&  SectionData?.images?.map((img, index)=> {
                        return (
                            <img src={img} key={index}
                            style={{
                                transform: `translate(${parseInt(styles?.images[index]?.n_displacementX)}px,${parseInt(styles?.images[index]?.n_displacementY)}px)`
                            }} />
                        )
                       }) }
                    </div>
                    <div className="txt-container">
                        <h2
                        onMouseLeave={handleRemoveTarget}
                        style={{
                            marginTop: parseInt(styles?.four_title?.n_marginTop),
                            marginBottom:  parseInt(styles?.four_title?.n_marginBottom),
                            color: styles?.four_title?.color,
                            fontSize: styles?.four_title?.n_fontSize,
                          
                        }}
                        onMouseEnter={(e) => handleTarget(e, 'sec4-title')}
                        className={`special-title sm-sc-fix ${target == "sec4-title" ? 'active-target' : ''} ${selected.element == "four_title" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'four_title')}
                        dangerouslySetInnerHTML={{__html: SectionData?.four_title}}
                        
                        />
                        <p dangerouslySetInnerHTML={{__html: SectionData?.four_text}} 
                         onMouseLeave={handleRemoveTarget}
                         onMouseEnter={(e) => handleTarget(e, 'four_text')}
                         className={` ${target == "four_text" ? 'active-target' : ''} ${selected.element == "four_text" ? 'active-selection' : ''}`}
                         onClick={(e) => handleElementEdit(e, 'four_text')}
                         style={{
                            marginTop: parseInt(styles?.four_text?.n_marginTop),
                            marginBottom:  parseInt(styles?.four_text?.n_marginBottom),
                            color: styles?.four_text?.color,
                            fontSize: styles?.four_text?.n_fontSize,
                          
                        }}
                        />
                        <button 
                        onMouseLeave={handleRemoveTarget}
                        style={{
                            marginTop: parseInt(styles?.four_button?.n_marginTop),
                            marginBottom:  parseInt(styles?.four_button?.n_marginBottom),
                            color: styles?.four_button?.color,
                            fontSize: styles?.four_button?.n_fontSize,
                          
                        }}
                        onMouseEnter={(e) => handleTarget(e, 'four_button')}
                        className={`big-btn sec-four-btn  ${target == "four_button" ? 'active-target' : ''} ${selected.element == "four_button" ? 'active-selection' : ''}`}
                        onClick={(e) => {handleElementEdit(e, 'four_button'); handleTypeFormClick();}}> {SectionData?.four_button}</button>
                    </div>
                </div>
            </div>
        )
}

export default SectionFour;