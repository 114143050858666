import logo from './logo.svg';
import './App.css';
import './css/home.css'
import { useRef } from 'react';
import Header from './components/header';
import Home from './pages/homepage';
import Footer from './components/footer';
import { useState, useEffect } from 'react';
import Editor from './components/editor';
import Banner from './components/banner';
import axios from "axios";
import { PopupButton } from '@typeform/embed-react';
import local from './local.json';
import { Spinner } from './components/icons';
import Marquee from 'react-fast-marquee';





function App() {

  const [selected, setSelected] = useState({ select_from: '', element: '' });
  const [elements, setElements] = useState(null);
  const [loading, setLoading] = useState(false);
  const [variantName, setVariantName] = useState('');
  const [banner, setBanner] = useState({bg: 'linear-gradient(90deg, #FF0 0%, #FF5C00 118.05%)', text: 'Make sure the sound is on and hit play!'});
  const [modalVisible, setModalVisible] = useState(false);
  const [editorVisible, setEditorVisible] = useState(false);

  const url = new URL(window.location.href);
  const buttonBRef = useRef(null);
  const variant = url.searchParams.get('variant');

  const getVariant = async () => {
    try {
      const response = await axios.get(`https://trainwithmo.com/api/get-variant.php?variant=${variant}`);

      // Update the state with the received data
      return [true, JSON.parse(response.data[0].variant_data)];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [false];
    }
  }
  const fallBack = async () => {
    try {
      const response = await axios.get(`https://trainwithmo.com/api/get-variant.php?variant=main`);

      // Update the state with the received data
      return [true, JSON.parse(response.data[0].variant_data)];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [false];
    }
  }
  const getThem = async () => {
    try {
      const result = await getVariant();

      if (result[0]) {
        setElements(result[1]);
        console.log(result[1]);
        // Do something with the data
      } else {
        //alert(`variant ${variant} not found`);
        const fallback = await fallBack();
        if (fallback[0]) {
          setElements(fallback[1]);
          console.log(fallback[1]);
        } else {

        }
      }
    } catch (error) {
      // Handle any other errors here
    }
  }
  useEffect(() => {
    getThem();
    //setElements(local);
    /*  if (getVariant()[0]) {
       console.log('varint here')
       setElements(getVariant())
     } else {
       console.log('variant dosnt exists')
       setElements(fallback())
     } */
  }, [])

  const handleStyleChange = (e, key, value, index) => {

    if (selected?.index >= 0) {
      let newElements = { ...elements };
      newElements[selected.select_from].styles[selected.element][selected.index][key] = e.target.value;

      setElements(newElements);

    } else {
      if (index >= 0) {
        let newElements = { ...elements };
        

        newElements[selected.select_from].styles[selected.element][index][key] = e.target.value;

        setElements(newElements);

      } else {
        if (value == 'packages_container') {
          let newElements = { ...elements };
          newElements[selected.select_from].styles.packages_container[key] = e.target.value;
          setElements(newElements);
          


        } else if(value == 'images_container') {
          let newElements = { ...elements };
          newElements[selected.select_from].styles.images_container[key] = e.target.value;
          setElements(newElements);
          
        } else {
         
          let newElements = { ...elements };
          newElements[selected.select_from].styles[selected.element][key] = e.target.value;
          setElements(newElements);
          
        }



      }


    }



  }
  const handleDataChange = (e) => {
    if (selected?.index >= 0) {

      let newElements = { ...elements };
      newElements[selected.select_from].data[selected.element][selected.index] = e.target.value;
      setElements(newElements);
      console.log('triggered +list');

    } else {
      console.log('triggered');
      let newElements = { ...elements };
      newElements[selected.select_from].data[selected.element] = e.target.value;
      setElements(newElements);
    }
  }


  const handleUpdateAllData = () => {
    setEditorVisible(false);
    setLoading(true);
    const stringfied = JSON.stringify(elements);
    axios.post('https://trainwithmo.com/api/set-variant.php', {
      variant_name: "main",
      variant_data: stringfied,
    })
      .then((response) => {
        console.log(response.data); // Handle success response here
        setModalVisible(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error creating funnel:', error); // Handle error here
        setModalVisible(false);
        setLoading(false);
      });

  }
  const publishVariant = () => {
    if (variantName) {
      setEditorVisible(false);
      setLoading(true);
      const stringfied = JSON.stringify(elements);
      axios.post('https://trainwithmo.com/api/publish-variant.php', {
        variant_name: variantName,
        variant_data: stringfied,
      })
        .then((response) => {
          console.log(response.data); // Handle success response here
          setModalVisible(false);
          setLoading(false);
          alert(`https://trainwithmo.com/admin?variant=${variantName}`)
        })
        .catch((error) => {
          console.error('Error creating funnel:', error); // Handle error here
          setModalVisible(false);
          setLoading(false);
        });
    }
  }

  const handleAddMoreToList = () => {
    let newElements = { ...elements };
    console.log(newElements[selected.select_from].styles.bullets);
    newElements[selected.select_from].styles.bullets =
      [
        ...newElements[selected.select_from].styles.bullets, {

          bgColor: 'red',
          color: 'white',
          n_fontSize: 25,
          n_marginBlock: 10,
          n_marginInline: 10,
          n_lineHeight: 30,
          fontWeight: 'bold',
          n_padding: 5,
        }];
    newElements[selected.select_from].data.bullets = [...newElements[selected.select_from].data.bullets, "Add More"];
  }
  const handleTypeFormClick = () => {
    // Trigger a click event on Button B
   
    console.log(buttonBRef.current.open())
  };
  
  return (
    <div className="App">
      
     {/*  {editorVisible && <div className='blurred-view' onClick={() => setEditorVisible(false)}></div>}
      {editorVisible && <button className='update-all-button' onClick={() => setModalVisible(true)} >Submit Page Update</button>} */}
      <Banner bg={banner.bg} bannerTxt={banner.text} />
      {elements && !loading ?
        <>
          <Header selected={selected} setSelected={setSelected} elements={elements.header} handleDataChange={handleDataChange} handleStyleChang={handleStyleChange} setEditorVisible={setEditorVisible}  />
          <Home selected={selected} setSelected={setSelected} data={elements} handleDataChange={handleDataChange} handleStyleChang={handleStyleChange} setEditorVisible={setEditorVisible} handleAddMoreToList={handleAddMoreToList} handleTypeFormClick={handleTypeFormClick} setBanner={setBanner}/>
          <Footer elements={elements.footer} edit={setEditorVisible} select={setSelected} selected={selected} />
        </>
        :
        <div style={{ width: '100%', height: '95vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative', overflowY: 'hidden' }}>
          <div>
            <Marquee>
              <img src="https://imagedelivery.net/pPmcWI57Uxy-_rb5sInlGg/1a6a91c6-855f-4d5c-ac29-32205ccad000/public" style={{width: '100%', height: '100vh', objectFit: "cover"}} />
              <img src="https://imagedelivery.net/pPmcWI57Uxy-_rb5sInlGg/e22a9d20-d3f3-47bd-9525-0bc839b82800/public" style={{width: '100%', height: '100vh', objectFit: "cover"}} />
              <img src="https://imagedelivery.net/pPmcWI57Uxy-_rb5sInlGg/c37f0ff7-a165-462f-a6b4-20b8f0a83a00/public" style={{width: '100%', height: '100vh', objectFit: "cover"}} />
            </Marquee>
          </div>
          <h1 style={{ fontWeight: '600', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-43%, -107%)', fontSize: 25, zIndex: 2, color: 'black',  }} className='gradiant-text'> Loading</h1>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2}}>
          <Spinner size={250} color="black"  />
          </div>
         
        </div>
      }
       {/* {editorVisible && <Editor states={{ elements, selected }} functions={{ setEditorVisible, handleStyleChange, handleDataChange, setElements }} />} */}
      <PopupButton ref={buttonBRef} id="https://qs4ch8tnnv0.typeform.com/to/mnbAZv3i" className="my-button" style={{ display: 'none' }}>
        
      </PopupButton>
    </div>
  );
}

export default App;
