import '../css/header.css';
import { CartIcon, PersonIcon } from './icons';
import BannerLogo from '../assets/imgs/profile-picture-storI 1.png';
import { useState, useEffect } from 'react';
import Editor from './editor';

function Header({ setSelected,selected,  elements, setEditorVisible}) {
    const [header, setHeaderData] = useState();
    const [styles, setStyles] = useState();
    const [stickyHeader, setStickyHeader] = useState(false);
   
    const [target, setTarget] = useState(null);

    useEffect(() => {
        setHeaderData(elements.data);
        setStyles(elements.styles);


        const handleScroll = () => {
            if (window.scrollY > 200) {
              // Add the class when scrolling is greater than 200
              setStickyHeader(false);
            } else {
              // Remove the class when scrolling is less than or equal to 200
              setStickyHeader(false);
            }
          };
      
          // Attach the scroll event listener
          window.addEventListener('scroll', handleScroll);
      
          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
       
    }, []);
    const handleTarget = (tar) => {
        setTarget(tar);
    }
    const handleRemoveTarget = (tar) => {
        setTarget(null);
    }

   
    const handleElementEdit = (element) => {
        setSelected({select_from: 'header', element: element});
        console.log(selected.element)
        setEditorVisible(true);
    }

    useEffect(() => {
        setHeaderData(elements.data);
        setStyles(elements.styles);
    }, [elements])
    return (
        <>
           
            <header className={`app-header ${stickyHeader ? 'sticky-header': ''}`} >
                <div className='header_container'>
                    {/* <a onClick={() => handleElementEdit('h_1')}
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('h_1')}
                        className={`${target == "h_1" ? 'active-target' : ''}`}>
                        {header?.h_1}</a> */}
                    <img src={header?.i_logoImage} 
                      onMouseLeave={handleRemoveTarget}
                      onMouseEnter={() => handleTarget('logo_img')} 
                      onClick={() => handleElementEdit('i_logoImage')}
                      className={`header-img-logo ${target == "logo_img" ? 'active-target' : ''} ${true ? 'active-selection' : ''}`}
                      style={{width: `${parseInt(styles?.i_logoImage?.n_size)}px`, height: 'auto', transform: `translate(${parseInt(styles?.i_logoImage?.n_up_down)}px, ${parseInt(styles?.i_logoImage?.n_left_right)}px )`}}
                      />
                    <div >

                        {/* <a
                            onMouseLeave={handleRemoveTarget}
                            onMouseEnter={() => handleTarget('h_2')}
                            className={`${target == "h_2" ? 'active-target' : ''}`}
                            onClick={() => handleElementEdit('h_2')}>{header?.h_2}</a>

                        <a onClick={() => setEditorVisible('h_3')}
                            onMouseLeave={handleRemoveTarget}
                            onMouseEnter={() => handleTarget('h_3')}
                            className={`${target == "h_3" ? 'active-target' : ''}`}>{header?.h_3}</a> */}

                    </div>

                </div>
               {/*  <a>
                    <PersonIcon />

                </a>
                <a>
                    <CartIcon />

                </a> */}
            </header>
        </>

    );
}

export default Header;