import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Fade } from "react-reveal";

const url = "https://www.coachingwithsadik.com/cdn/shop/products/coaching-8-week-diet-training_750x.jpg?v=1629580765";
const packages = [
    { img: url, title: 'gay package ', sub: 'gay in two month', priece: '1 AED' },
    { img: url, title: 'Homo package', sub: 'super gay in one month', priece: '1000 AED' },
    { img: url, title: 'flee johnoson package', sub: 'booty worrior in one week', priece: 'trust me bro you can"t afford this' },
]

function SectionTwo({ elements, edit, select, selected, handleAddMoreToList, handleTypeFormClick }) {
    const [SectionData, setSectionData] = useState(null);
    const [styles, setStyles] = useState(null);
    const [target, setTarget] = useState(null);
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);


    }, []);
    const handleTarget = (tar) => {
        setTarget(tar);
    }
    const handleRemoveTarget = (tar) => {
        setTarget(null);
    }
    const handleElementEdit = (e, element, index) => {
        e.stopPropagation();
        if (index >= 0) {
            select({ select_from: 'two', element: element, index });

        } else {

            select({ select_from: 'two', element: element });
        }
        edit(true);
    }
    useEffect(() => {
        setSectionData(elements.data);
        setStyles(elements.styles);

    }, [elements]);


    /*  useEffect(() => {
         console.log(styles);
         
     }, [SectionData]) */

    return (
        <section className="section-two">
            {setSectionData ?
                <>
                    <h2
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('sec2_title1')}
                        className={`title-sm-sc margin-fix title-m-top robot-condesed-title ${target == "sec2_title1" ? 'active-target' : ''} ${selected.element == "sec2_title1" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'sec2_title1')}
                        dangerouslySetInnerHTML={{ __html: SectionData?.sec2_title1 }}
                        style={{
                            background: styles?.sec2_title1?.bgColor, color: styles?.sec2_title1?.color, fontSize: parseInt(styles?.sec2_title1?.n_fontSize),
                            marginTop: parseInt(styles?.sec2_title1?.n_marginTop),
                            marginBottom: parseInt(styles?.sec2_title1?.n_marginBottom),
                            lineHeight: `${styles?.sec2_title1?.n_lineHeight}px`,
                            fontWeight: styles?.sec2_title1?.fontWeight, padding: parseInt(styles?.sec2_title1?.n_padding)
                        }} />
                    <ul style={{
                        maxWidth: `${(styles?.bullets_container?.n_maxWidth)}px`,
                        background: styles?.bullets_container?.bgColor,
                        marginTop: parseInt(styles?.bullets_container?.n_marginTop),
                        marginBottom: parseInt(styles?.bullets_container?.n_marginBottom),

                        marginBottom: parseInt(styles?.bullets_container?.n_marginBottom),
                        marginTop: parseInt(styles?.bullets_container?.n_marginTop),

                        textAlign: styles?.bullets_container?.textAlign || 'left'
                    }}
                        onClick={(e) => handleElementEdit(e, `bullets_container`)}
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget(`bullets_container`)}
                        className={`no-padding-sc  ${target == `bullets_container` ? 'active-target' : ''} ${selected.element == 'bullets_container' ? 'active-selection' : ''}`}>
                        {
                            SectionData?.bullets.map((item, index) => {

                                return (
                                    <li
                                        key={index}
                                        onMouseLeave={handleRemoveTarget}
                                        onMouseEnter={() => handleTarget(`bullets_text-${index}`)}
                                        className={`${target == `bullets_text-${index}` ? 'active-target' : ''} ${selected.element == 'bullets' && selected.index == index ? 'active-selection' : ''}`}
                                        onClick={(e) => handleElementEdit(e, `bullets`, index)}
                                        dangerouslySetInnerHTML={{ __html: item }}
                                        style={{
                                            backgroundColor: styles?.bullets[index]?.bgColor, color: styles?.bullets[index]?.color,
                                            fontSize: parseInt(styles?.bullets[index]?.n_fontSize),
                                            marginBlock: parseInt(styles?.bullets[index]?.n_marginBlock),
                                            marginInline: parseInt(styles?.bullets[index]?.n_marginInline),
                                            lineHeight: `${styles?.bullets[index]?.n_lineHeight}px`,
                                            fontWeight: styles?.bullets[index]?.fontWeight,
                                            padding: parseInt(styles?.bullets[index]?.n_padding)
                                        }}
                                    />

                                )
                            })
                        }
                        {selected.element == 'bullets_container' && <button className="active-selection" onClick={handleAddMoreToList}> +  </button>}
                    </ul>
                    <button style={{ marginBottom: 50, }}
                     onMouseLeave={handleRemoveTarget}
                     onMouseEnter={() => handleTarget('button_2')}
                     className={`big-btn sm-sc-fix ${target == "button_2" ? 'active-target' : ''} ${selected.element == "button_2" ? 'active-selection' : ''}`}
                     onClick={(e) => {handleElementEdit(e, 'button_2'); handleTypeFormClick();}} 
                    dangerouslySetInnerHTML={{__html: SectionData?.button_2}} />
                        
                    

                    <div
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('sec2-packages')}
                        className={`${target == "sec2-packages" ? 'active-target' : ''} ${selected.element == "packages" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'packages')}
                        style={{
                            background: styles?.packages_container?.bgColor, color: styles?.packages_container?.color,
                            fontSize: parseInt(styles?.packages_container?.n_fontSize),
                            marginBlock: parseInt(styles?.packages_container?.n_marginBlock),
                            marginTop: parseInt(styles?.packages_container?.n_marginTop),
                            lineHeight: `${styles?.packages_container?.n_lineHeight}px`,
                            width: `${parseInt(styles?.packages_container?.n_width) || 100}%`,
                            padding: `${parseInt(styles?.packages_container?.n_padding)}px`,
                            fontWeight: styles?.packages_container?.fontWeight, padding: parseInt(styles?.packages_container?.n_padding),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}

                    >
                        <h2
                            onMouseLeave={handleRemoveTarget}
                            onMouseEnter={() => handleTarget('sec2-title')}
                            className={`gradiant-text ${target == "sec2-title" ? 'active-target' : ''} ${selected.element == "title" ? 'active-selection' : ''}`}
                            onClick={(e) => handleElementEdit(e, 'title')}
                            dangerouslySetInnerHTML={{ __html: SectionData?.title }}
                            style={{
                                backgroundColor: styles?.title?.bgColor, color: styles?.title?.color, fontSize: parseInt(styles?.title?.n_fontSize),
                                marginBlock: parseInt(styles?.title?.n_marginBlock),
                                marginInline: parseInt(styles?.title?.n_marginInline),
                                lineHeight: `${styles?.title?.n_lineHeight}px`,
                                fontWeight: styles?.title?.fontWeight, padding: styles?.title?.n_padding,
                            }} />
                        <div className="home_packages">

                            {SectionData?.packages?.map((item, index) => {
                                return (
                                    <Fade left={index % 2 === 0} right={index % 2 !== 0}>
                                        <div style={{ marginBlock: '3rem' }}>
                                            <img className="cursor shining-box" src={item.img} onClick={(e) => { e.stopPropagation(); handleTypeFormClick(); }} />
                                            <p
                                                style={{
                                                    backgroundColor: styles?.packages[index]?.bgColor, color: styles?.packages[index]?.color,
                                                    fontSize: parseInt(styles?.packages[index]?.n_fontSize),
                                                    marginBlock: parseInt(styles?.packages[index]?.n_marginBlock),
                                                    marginInline: parseInt(styles?.packages[index]?.n_marginInline),
                                                    lineHeight: `${styles?.packages[index]?.n_lineHeight}px`,
                                                    fontWeight: styles?.packages[index]?.fontWeight, padding: parseInt(styles?.packages[index]?.n_padding)
                                                }}
                                                dangerouslySetInnerHTML={{ __html: item.title }} />
                                            {/* <p
                                                style={{
                                                    backgroundColor: styles?.packages[index]?.bgColor, color: styles?.packages[index]?.color,
                                                    fontSize: parseInt(styles?.packages[index]?.n_fontSize),
                                                    marginBlock: parseInt(styles?.packages[index]?.n_marginBlock),
                                                    marginInline: parseInt(styles?.packages[index]?.n_marginInline),
                                                    lineHeight: `${styles?.packages[index]?.n_lineHeight}px`,
                                                    fontWeight: styles?.packages[index]?.fontWeight, padding: parseInt(styles?.packages[index]?.n_padding)
                                                }}  dangerouslySetInnerHTML={{ __html: item.sub }} /> */}
                                            <p
                                                style={{
                                                    backgroundColor: styles?.packages[index]?.bgColor, color: styles?.packages[index]?.color,
                                                    fontSize: parseInt(styles?.packages[index]?.n_fontSize),
                                                    marginBlock: parseInt(styles?.packages[index]?.n_marginBlock),
                                                    marginInline: parseInt(styles?.packages[index]?.n_marginInline),
                                                    lineHeight: `${styles?.packages[index]?.n_lineHeight}px`,
                                                    fontWeight: styles?.packages[index]?.fontWeight, padding: parseInt(styles?.packages[index]?.n_padding)
                                                }} dangerouslySetInnerHTML={{ __html: item.priece }} />
                                        </div>
                                    </Fade>
                                )
                            })}
                        </div>
                    </div>
                    <h2
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('second_text')}
                        className={`special-title title-sm-sc robot-condesed-title ${target == "second_text" ? 'active-target' : ''} ${selected.element == "second_text" ? 'active-selection' : ''}`}
                        onClick={(e) => { handleElementEdit(e, 'second_text'); }}
                        dangerouslySetInnerHTML={{ __html: SectionData?.second_text }}
                        style={{
                            background: styles?.second_text?.bgColor, color: styles?.second_text?.color, fontSize: parseInt(styles?.second_text?.n_fontSize),
                            marginTop: parseInt(styles?.second_text?.n_marginTop),
                            marginBottom: parseInt(styles?.second_text?.n_marginBottom),
                            lineHeight: `${styles?.second_text?.n_lineHeight}px`,
                            fontWeight: styles?.second_text?.fontWeight, padding: parseInt(styles?.second_text?.n_padding), paddingInline: parseInt(styles?.second_text?.n_paddingInline)
                        }} />

                    <ul 
                        onClick={(e) => handleElementEdit(e, `bullets2_container`)}
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget(`bullets2_container`)}
                        className={`arrow-list  width-sm ${target == `bullets2_container` ? 'active-target' : ''} ${selected.element == 'bullets2_container' ? 'active-selection' : ''}`}
                        style={{
                            maxWidth: `${(styles?.bullets2_container?.n_maxWidth)}px`,
                            background: styles?.bullets2_container?.bgColor,
                            marginTop: parseInt(styles?.bullets2_container?.n_marginTop),
                            marginBottom: parseInt(styles?.bullets2_container?.n_marginBottom),
                            lineHeight: parseInt(styles?.bullets_container?.n_lineHeight),
                            fontSize: parseInt(styles?.bullets_container?.n_fontSize),
                            
                            textAlign: styles?.bullets_container?.textAlign || 'left'
                        }}>
                        {
                            SectionData?.bullets2.map((item, index) => {
                                return (
                                    <li dangerouslySetInnerHTML={{ __html: item }} key={index} 
                                    onMouseLeave={handleRemoveTarget}
                                    onMouseEnter={() => handleTarget(`bullets2_text-${index}`)}
                                    className={`${target == `bullets2_text-${index}` ? 'active-target' : ''} ${selected.element == 'bullets2' && selected.index == index ? 'active-selection' : ''}`}
                                    onClick={(e) => handleElementEdit(e, `bullets2`, index)}
                                    style={{
                                        backgroundColor: styles?.bullets2[index]?.bgColor, color: styles?.bullets2[index]?.color,
                                        fontSize: parseInt(styles?.bullets2[index]?.n_fontSize),
                                        marginBlock: parseInt(styles?.bullets2[index]?.n_marginBlock),
                                        marginInline: parseInt(styles?.bullets2[index]?.n_marginInline),
                                        lineHeight: `${styles?.bullets2[index]?.n_lineHeight}px`,
                                        fontWeight: styles?.bullets2[index]?.fontWeight,
                                        padding: parseInt(styles?.bullets2[index]?.n_padding)
                                    }}/>

                                )
                            })
                        }

                    </ul>
                    <h2
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('third_text')}
                        className={`sm-sc-fix cursor ${target == "third_text" ? 'active-target' : ''} ${selected.element == "third_text" ? 'active-selection' : ''}`}
                        onClick={(e) => { handleElementEdit(e, 'third_text'); handleTypeFormClick(); }}
                        dangerouslySetInnerHTML={{ __html: SectionData?.third_text }}
                        style={{
                            background: styles?.third_text?.bgColor, color: styles?.third_text?.color, fontSize: parseInt(styles?.third_text?.n_fontSize),
                            marginTop: parseInt(styles?.third_text?.n_marginTop),
                            marginBottom: parseInt(styles?.third_text?.n_marginBottom),
                            lineHeight: `${styles?.third_text?.n_lineHeight}px`,
                            fontWeight: styles?.third_text?.fontWeight, padding: parseInt(styles?.third_text?.n_padding),
                            display: 'flex',
                            alignItems: 'center',
                        }} />
                    <h2
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('fourth_text')}
                        className={`${target == "fourth_text" ? 'active-target' : ''} ${selected.element == "fourth_text" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'fourth_text')}
                        dangerouslySetInnerHTML={{ __html: SectionData?.fourth_text }}
                        style={{
                            backgroundColor: styles?.fourth_text?.bgColor, color: styles?.fourth_text?.color, fontSize: parseInt(styles?.fourth_text?.n_fontSize),
                            marginTop: parseInt(styles?.fourth_text?.n_marginTop),
                            marginBottom: parseInt(styles?.fourth_text?.n_marginBottom),
                            lineHeight: `${styles?.fourth_text?.n_lineHeight}px`,
                            fontWeight: styles?.fourth_text?.fontWeight, padding: parseInt(styles?.fourth_text?.n_padding)
                        }} />
                    <h2
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('fifth_text')}
                        className={`robot-condesed ${target == "fifth_text" ? 'active-target' : ''} ${selected.element == "fifth_text" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'fifth_text')}
                        dangerouslySetInnerHTML={{ __html: SectionData?.fifth_text }}
                        style={{
                            backgroundColor: styles?.fifth_text?.bgColor, color: styles?.fifth_text?.color, fontSize: parseInt(styles?.fifth_text?.n_fontSize),
                            marginTop: parseInt(styles?.fifth_text?.n_marginTop),
                            marginBottom: parseInt(styles?.fifth_text?.n_marginBottom),
                            lineHeight: `${styles?.fifth_text?.n_lineHeight}px`,
                            fontWeight: styles?.fifth_text?.fontWeight, padding: parseInt(styles?.fifth_text?.n_padding)
                        }} />

                    <div style={{ width: '100%' }}
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('images')}
                        className={`${target == "images" ? 'active-target' : ''}  ${selected.element == "images" ? 'active-selection' : ''}`}
                        onClick={(e) => handleElementEdit(e, 'images')
                        }
                    >
                        <div>
                            {SectionData?.images.map((image, index) => {
                                return (<img src={image} key={index}
                                    style={{
                                        width: parseInt(styles?.images[index]?.n_width),
                                        height: 'auto',
                                        marginInline: 10
                                    }} />)
                            })}


                        </div>
                    </div>

                    <div style={{ width: '100%', background: styles?.marquee_text?.bgColor, marginTop: parseInt(styles?.marquee_text?.n_marginTop), marginBottom: parseInt(styles?.marquee_text?.n_marginBottom), padding: parseInt(styles?.marquee_text?.n_padding) }}
                        onMouseLeave={handleRemoveTarget}
                        onMouseEnter={() => handleTarget('marquee_text')}
                        className={`${target == "marquee_text" ? 'active-target' : ''} ${selected.element == "marquee_text" ? 'active-selection' : ''}`}
                    >

                        <Marquee style={{ justifyContent: 'space-around', width: '100%' }}>
                            <h1
                                className="sm-sc-fix"
                                onClick={(e) => handleElementEdit(e, 'marquee_text')}
                                style={{
                                    marginInline: '8vw',
                                    color: styles?.marquee_text?.color,
                                    fontSize: parseInt(styles?.marquee_text?.n_fontSize),


                                    fontWeight: styles?.marquee_text?.fontWeight,
                                    lineHeight: `${styles?.marquee_text?.n_lineHeight}px`,
                                }}
                            >
                                {SectionData?.marquee_text}</h1>
                            <h1
                                className="sm-sc-fix"
                                onClick={(e) => handleElementEdit(e, 'marquee_text')}
                                style={{
                                    marginInline: '8vw',
                                    color: styles?.marquee_text?.color,
                                    fontSize: parseInt(styles?.marquee_text?.n_fontSize),


                                    fontWeight: styles?.marquee_text?.fontWeight,
                                    lineHeight: `${styles?.marquee_text?.n_lineHeight}px`,
                                }}
                            >
                                {SectionData?.marquee_text}</h1>
                            <h1
                                className="sm-sc-fix"
                                onClick={(e) => handleElementEdit(e, 'marquee_text')}
                                style={{

                                    color: styles?.marquee_text?.color,
                                    fontSize: parseInt(styles?.marquee_text?.n_fontSize),


                                    fontWeight: styles?.marquee_text?.fontWeight,
                                    lineHeight: `${styles?.marquee_text?.n_lineHeight}px`,
                                }}
                            >
                                {SectionData?.marquee_text}</h1>

                        </Marquee>
                    </div>
                </>
                :
                <>
                </>}


        </section>
    )
}

export default SectionTwo;